import { JsonProperty, Serializable } from "typescript-json-serializer"
// @ts-ignore
import { getIdFromUrl } from "vue-youtube"

@Serializable()
export default class CompanyHistory {
  @JsonProperty("vdo_link") public vdoLink?: string

  @JsonProperty({ name: "vdo_link", beforeDeserialize: getIdFromUrl }) public vdoId?: string

  @JsonProperty() status!: string
}
