



































































import { Component, Vue, Watch } from "vue-property-decorator"
import AboutUsAPI from "@/api/aboutUs/aboutUs"
import CompanyProfile from "@/types/aboutUs/CompanyProfile"
import { deserialize } from "typescript-json-serializer"
import CompanyLocation from "@/types/aboutUs/CompanyLocation"
import CompanyHistory from "@/types/aboutUs/CompanyHistory"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
// @ts-ignore
import AOS from "aos"

@Component({
  name: "CompanyProfilePage",
  components: { SectionUnderLine }
})
export default class CompanyProfilePage extends Vue {
  private profile: CompanyProfile = new CompanyProfile()

  private location: CompanyLocation = new CompanyLocation()

  private history: CompanyHistory = new CompanyHistory()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    const cpPromise = AboutUsAPI.getCompanyProfile().then((resp) => {
      const { data } = resp
      this.profile = deserialize<CompanyProfile>(data, CompanyProfile)
    })
    const clPromise = AboutUsAPI.getCompanyLocation().then((resp) => {
      const { data } = resp
      this.location = deserialize<CompanyLocation>(data, CompanyLocation)
    })
    const chPromise = AboutUsAPI.getCompanyHistory().then((resp) => {
      const { data } = resp
      this.history = deserialize<CompanyHistory>(data, CompanyHistory)
    })
    Promise.all([cpPromise, clPromise, chPromise]).then(() => {
      AOS.refresh()
    })
  }

  mounted() {
    this.fetchData()
  }

  private profileTemplate = [
    {
      label: "ชื่อบริษัท",
      value: "companyName",
      align: "center"
    },
    {
      label: "ชื่อย่อ",
      value: "companyShortName",
      align: "center"
    },
    {
      label: "เลขทะเบียนบริษัท",
      value: "companyNumber",
      align: "center"
    },
    {
      label: "ประเทภธุรกิจ",
      value: "typeBusiness",
      align: "start"
    },
    {
      label: "ทุนจดทะเบียน",
      value: "registeredCapital",
      align: "start"
    }
  ]

  private locationTemplate = [
    {
      label: "สำนักงานใหญ่",
      value: "headquarters"
    },
    {
      label: "สำนักงานสาขาที่ 1",
      value: "firstBranch"
    },
    {
      label: "สำนักงานสาขาที่ 2",
      value: "secondBranch"
    },
    {
      label: "สำนักงานสาขาที่ 3",
      value: "thirdBranch"
    },
    {
      label: "Call Center",
      value: "callCenter"
    },
    {
      label: "Website",
      value: "website"
    },
    {
      label: "E-mai Address",
      value: "email"
    }
  ]
}
